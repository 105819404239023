import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useNavigate } from "react-router-dom";

const ListeningLandingPage = () => {
  const navigate = useNavigate();

  const handleTypeSelection = (type) => {
    if (type === 'Conversation') {
      navigate('/listening/conversation-questions');
    } else if (type === 'Lecture') {
      // You can implement this later for lectures
      console.log('Lecture selected');
      navigate('/listening/lecture-questions');

    }
  };

  const renderButton = (title, description, onClick) => {
    return (
      <button onClick={onClick} className="w-full flex">
        <div className="bg-amber-100 transition-colors duration-300 rounded-lg shadow-md p-6 w-full flex flex-col min-h-[170px] justify-between">
          <div>
            <h3 className="text-xl font-bold text-amber-800">{title}</h3>
          </div>
          <p className="text-amber-700">{description}</p>
        </div>
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Listing</title>
        <meta
          name="description"
          content="Track your progress in the TOEFL Listening section. Review your performance across various audio clips and question types, focusing on areas like main ideas, details, and inferences. Stay informed of your strengths and identify opportunities for improvement."
        />
        <link rel="canonical" href="/listening" />
      </Helmet>

      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex flex-col items-center justify-center px-4 py-12">
        <div className="bg-white rounded-lg shadow-md p-8 max-w-2xl w-full mb-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            Select the type of listening exercise:
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {renderButton(
              "Conversation",
              "Listen to a conversation and answer questions",
              () => handleTypeSelection('Conversation')
            )}
            {renderButton(
              "Lecture",
              "Listen to a lecture and answer questions",
              () => handleTypeSelection('Lecture')
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ListeningLandingPage;