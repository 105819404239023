// File: src/Sections/Writing.js
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import axios from "axios";
import Logout from "../Login/Logout";
import DisplayAttempts from "./displayWritingAttempts/DisplayAttempts";

const Writing = () => {
  const [section, setSection] = useState("")
  const navigate = useNavigate();

  const handleWritingNav = (title) => {
    console.log(title)
    const section =
      title === "Practice Academic Discussion"
        ? "TOEFL#academicDiscussion"
        : "TOEFL#integratedWriting";
    setSection(section)
    navigate("/writing-attempt-questions", {
      state: { section: section },
    });
  }

  // if(section)return <DisplayAttempts section={section} />;

  const renderButton = (type, title, description) => {

    return (
      <div className="block relative cursor-pointer">
        <div
          className={`bg-amber-100 transition-colors  duration-300 rounded-lg shadow-md p-6 hover:bg-amber-200
          }`}
          onClick={() => handleWritingNav(title)}
        >
          <h3 className="text-xl font-bold text-amber-800 mb-2">{title}</h3>
          <p className="text-amber-700">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Your progress in Writing</title>
        <meta
          name="description"
          content="Track your progress in the Writing section. Review your performance in both Academic Discussion and Integrated Writing tasks. Monitor your ability to structure essays, present arguments, and synthesize information from reading and listening materials to improve your writing skills and achieve better results."
        />
        <link rel="canonical" href="/writing-attempts" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-5xl space-y-8">
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Select a Writing Task
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                {renderButton(
                  "academic_discussion",
                  "View Your Academic Discussion Grades",
                  "Check your past attempts and see your grades for academic discussion tasks."
                )}
              </div>
              <div>
                {renderButton(
                  "integrated_writing",
                  "View Your Integrated Writing Grades",
                  "Review your grades for integrated writing tasks and track your progress."
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Writing;
