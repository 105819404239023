import axios from 'axios';
import { useExercise } from './ExerciseContext';
import { useEffect } from 'react';
import { set } from 'react-hook-form';
import getPersonalData from '../GetPersonalData/GetpersonalData';

export const useSubmission = (id) => {
    const { userAnswers, submissionResult, setSubmissionResult, setError, exerciseData, currentQuestionIndex} = useExercise();

    useEffect(() => {
      console.log("From useSubmission", submissionResult)
      if (submissionResult) {
        handleUploadDatabase();

      }
    }
    , [submissionResult])
  
    const handleSubmit = async () => { 
      console.log('Submitting answers:', userAnswers); // Debugging log
      try {
        // Make sure to stringify keys for consistency
        const formattedAnswers = Object.fromEntries(
          Object.entries(userAnswers).map(([key, value]) => [String(key), value])
        );
        console.log(exerciseData)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit-listening-exercise`, {
          exercise_type: exerciseData.exercise_type,
          exercise_id: id,
          answers: formattedAnswers, // Send formatted answers
        });
  
        console.log('Submission response:', response.data); // Debugging log to check response
        setSubmissionResult(response.data);

      } catch (error) {
        console.error('Error submitting exercise:', error);
        setError('Failed to submit exercise. Please try again later.');
      }

    };

    const handleUploadDatabase = async () => {
      if(exerciseData === null || submissionResult.upload){
        console.log("exerciseData is null")
        return;
      }



      const exerciseType = exerciseData.exercise_type;
      const capitalizedExerciseType = exerciseType.charAt(0).toUpperCase() + exerciseType.slice(1);
      const currentDateTime = new Date().toISOString();
      console.log("Sub result form useSubmission before", submissionResult);
      console.log("Sub result form useSubmission after =-=-=", submissionResult);

      const userEmail = await getPersonalData();


      const newtry = {
        Answers:{...submissionResult, upload: true},
        score: submissionResult.score,
        timestamp: currentDateTime,
      };
      const data = {
        Email: userEmail.email,//"alexramirez2027@gmail.com",
        Section: `TOEFL#listening#${capitalizedExerciseType}#${id}`,
        Score: submissionResult.score,  // Convert to string with 2 decimal places
        NewTry: newtry, 
      };
      if (submissionResult.score){
        try {
          console.log("Data from handleUploadDatabase", data)
          // Send POST request to the Flask backend
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/result-send`,
            data,  // Include the formatted data as the request body
            {
              headers: {
                'Content-Type': 'application/json',  // Ensure correct content type
              },
            }
          );
          // Handle successful response
          console.log('Response:', response.data);
          
          
        } catch (error) {
          // Handle errors if the request fails
          console.error("Error sending data:", error);
          console.log("ERROR")
        }
      }
    };
  
    return {
      handleSubmit,
    };
  };
