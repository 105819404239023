import React,{useState,useEffect} from "react";
import {  NavLink } from "react-router-dom";
import Logout from "../Login/Logout";
import { Helmet } from "react-helmet-async";
import { IoMdClose } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../atom";

const Dashboard = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [questionNum, setQuestionNum] = useAtom(questionAtom);
  const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
  const [answers, setAnswers] = useAtom(answersAtom);
  const [score, setScore] = useAtom(scoreAtom);

  useEffect(() => {
    const hasVisited = localStorage.getItem("OpenStudyAi-hasVisited");
    if (hasVisited !== null) {
      setShowPopup(true);
    }setQuestionNum("")
    setAttemptIndex("")
    setAnswers("")
    setScore('')
    
  }, []);


  const handleClosePopup = () => {
    setShowPopup(false);
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="You can choose from four sections—Reading, Writing, Speaking, and Listening—to start practicing. Tailor your study experience by selecting any section and dive into targeted exercises to boost your TOEFL skills."
        />
        <link rel="canonical" href="/dashboard" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <Logout />
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full space-y-8">
          {showPopup && (
            <>
              <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
                <div className=" relative bg-white border border-gray-300 rounded-lg shadow-lg p-6 w-[90%]">
                  <div className="text-end absolute top-2 right-2 ">
                    <button
                      onClick={handleClosePopup}
                      className="text-red-900  text-2xl rounded "
                    >
                      <IoMdClose />
                    </button>
                  </div>
                  <div className="p-2">
                    <p className="text-lg font-bold mb-4">
                      We would greatly appreciate hearing about your experience
                      with our website.
                    </p>
                    <p className="text-lg font-bold mb-4 w-[100%] ">
                      We are excited to invite you to join our official Discord
                      community for OpenStudyAI! Connect with fellow TOEFL exam
                      aspirants, discuss challenges, share feedback, and explore
                      new feature suggestions. Join us to enhance your learning
                      experience and be part of our growing community. Click{" "}
                      <a
                        href="https://discord.gg/JFTwXm2Rgt"
                        className="text-blue-500 underline inline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>{" "}
                      to join!
                    </p>
                    <div className="flex gap-5">
                      <a
                        href="https://forms.gle/BopWYfDohLpAdtxj9"
                        className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200  block mb-4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Give Feedback
                      </a>{" "}
                      <a
                        href="https://discord.gg/JFTwXm2Rgt"
                        className="flex items-center gap-2 bg-[#6b75ec] text-white px-4 py-2 rounded-md hover:bg-[#535ee0] transition duration-2000  mb-4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaDiscord /> <div>Join Discord</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="bg-white rounded-lg shadow-md p-8">
            <div>
              <h2 className="text-2xl font-bold text-gray-800 mb-6">
                Select the section you want to practice
              </h2>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 justify-around">
                <div className="w-[300px] h-[300px] shadow-md rounded-lg mr-2 mb-2">
                  <div className="border-t-8 border-indigo-500 rounded-t-lg"></div>
                  <div className="flex h-[100%] flex-col justify-around">
                    <div className="text-xl p-2 font-bold">Reading</div>
                    <div className="flex flex-col p-4 justify-between gap-3 max-w-[200px]">
                      <NavLink
                        to="/reading"
                        className="bg-indigo-400 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Practice Reading
                      </NavLink>
                      <NavLink
                        to={{
                          pathname: "/reading-attempts",
                          state: "reading",
                        }}
                        className="bg-indigo-400 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Your Progress
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="w-[300px] h-[300px] shadow-md rounded-lg mr-2 mb-2">
                  <div className="border-t-8 border-amber-500 rounded-t-lg"></div>
                  <div className="flex h-full flex-col justify-around">
                    <div className="text-xl p-2 font-bold">Writing</div>
                    <div className="flex p-4 flex-col justify-between gap-3 max-w-[200px]">
                      <NavLink
                        to="/writing"
                        className="bg-amber-400 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Practice Writing
                      </NavLink>
                      <NavLink
                        to="/writing-attempts"
                        className="bg-amber-400 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Your Progress
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="w-[300px] h-[300px] shadow-md rounded-lg mr-2 mb-2">
                  <div className="border-t-8 border-gray-500 rounded-t-lg"></div>
                  <div className="flex h-full flex-col justify-around">
                    <div className="text-xl p-2 font-bold">Listening</div>
                    <div className="flex p-4 flex-col justify-between gap-3 max-w-[200px]">
                      <NavLink
                        to="/Listening"
                        className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Practice Listening
                      </NavLink>
                      <NavLink
                        to="/listeningAttempts"
                        className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Your Progress
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="relative w-[300px] h-[300px] shadow-md rounded-lg group ">
                  <div className="border-t-8 border-red-500 rounded-t-lg"></div>
                  <div className="flex h-[100%] flex-col justify-around">
                    <div className="text-xl p-2 font-bold">Speaking</div>
                    <div className="flex flex-col justify-between gap-3 max-w-[200px] p-4">
                      <NavLink
                        to="/Speaking"
                        className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Practice Speaking
                      </NavLink>
                      <NavLink
                        to="/speakingAttempts"
                        className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
                      >
                        Your Progress
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;