import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../atom";
import getPersonalData from "../GetPersonalData/GetpersonalData";

import { useExercise } from '../Listening/ExerciseContext';
import { set } from "react-hook-form";


const ListeningGrades = ({exerciseType}) => {
  const [error, setError] = useState(false);
  const  section  = exerciseType || "Conversation";
  const [questionsData, setQuestionsData] = useState(null);
  const [questionNum, setQuestionNum] = useAtom(questionAtom);
  const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
  const [answers, setAnswers] = useAtom(answersAtom);
  const [score, setScore] = useAtom(scoreAtom);

  const {submissionResult, setSubmissionResult, setFromGrading, setUserAnswers, UserAnswers} = useExercise();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAttemptsData = async () => {
      const getEmail = await getPersonalData();
      const sec = "TOEFL#listening#" + section;
      const requestData = {
        Email: getEmail.email,
        SectionPrefix: sec,
      };
      console.log("Request Data", requestData);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/get-all-listening-results`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.statusText === "OK") {
          setQuestionsData(response.data);
          console.log(response.data);
          setError(false);
        }
      } catch (e) {
        setError(true);
      }
    };

    fetchAttemptsData();
  }, []);

  const handleAttemptClick = (question, attemptIndex) => {
    const selectedAttempt = questionsData[question][attemptIndex];
    setQuestionNum(question);
    setAttemptIndex(attemptIndex);
    setAnswers(selectedAttempt.Answers);
    //
    setSubmissionResult(selectedAttempt.Answers);
    setFromGrading(true);

    setUserAnswersFromPreviousSubmission(selectedAttempt.Answers);

    setScore(selectedAttempt.Score);

    
    localStorage.setItem("OpenStudyAiListeningExamNum", question.substring(9));
    //navigate("/listeningTask");
    navigate(`/listening/${exerciseType}/${question.substring(9)}`);
  };

  const setUserAnswersFromPreviousSubmission = (submissionResult) => {
    setUserAnswers((prev) => {
        const updatedAnswers = { ...prev };
        submissionResult.results.forEach((result) => {
            const { question_number, user_answer } = result;
  
        // Save the user_answer directly for each question_number, whether it's a single answer or an array
        updatedAnswers[question_number] = user_answer;
    });
  
      return updatedAnswers;
    });
  
  };


  if (error) {
    return <div className="flex justify-center items-center text-xl font-bold mt-[100px]">You haven't attempted this section</div>;
  }

  if (!questionsData) {
    return (
      <div className="h-full w-full flex justify-center p-12">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="p-4">
      {Object.entries(questionsData).map(([question, attempts], index) => (
        <div
          key={index}
          className="mb-6 p-6 bg-white rounded-lg shadow-lg border border-gray-300"
        >
          <h2 className="text-2xl mb-4 font-semibold border-b border-gray-300 pb-2">
            Version {question.substring(9)}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {attempts
              .sort((a, b) => new Date(b.TimeStamp) - new Date(a.TimeStamp))
              .map((attempt, attemptIndex) => {
                const formattedDate = new Date(attempt.TimeStamp).toLocaleString();
                return (
                  <button
                    key={attemptIndex}
                    className="bg-amber-400 hover:bg-amber-500 text-gray-700 font-bold py-2 px-4 rounded"
                    onClick={() => handleAttemptClick(question, attemptIndex)}
                  >
                    <div>Score {attempt.Score}</div>
                    <div>
                      <span className="text-sm text-gray-600">{formattedDate}</span>
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListeningGrades;