import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../../LoadingPage/Loading";
import { Helmet } from "react-helmet-async";
const IntegratedWritingQuestions = () => {
  const [noQuestions, setNoQuestions] = useState(null);
  const [scores, setScores] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getNumberOfQuestions = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/integrated-writing-number`
    );
    if (response.statusText === "OK") setNoQuestions(response.data.message);
    const token = localStorage.getItem("OpenStudyAi-token");
    try{
    const getEmail = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/protected`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const requestData = {
      Email: getEmail.data.logged_in_as.email,
      SectionPrefix: "TOEFL#integratedWriting",
    };
    const scr = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/integrated-writing-results`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json", // Ensure correct content type
        },
      }
    );
    if (scr.statusText === "OK") setScores(scr.data);
    setIsLoading(false);
  }catch(e){
      setIsLoading(false);
    console.log(e)
  }
  };
  useEffect(() => {
    getNumberOfQuestions();
  }, []);
  const buttons = Array.from(
    { length: noQuestions },
    (_, i) => `Question ${i + 1}`
  );

  const handleQuestion = (index) => {
    localStorage.setItem("OpenStudyAiIntegratedWritingQuestionNo", index + 1);
    navigate("/integrated-writing-instructions");
  };

  if (!noQuestions || isLoading ) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Integrated-Writing</title>
        <meta
          name="description"
          content="Integrated Writing Section: Choose any prompt to start practicing integrated writing skills. Combine information from reading passages and listening materials to craft cohesive and well-supported essays. Develop your ability to synthesize and analyze content, while receiving instant feedback to enhance your writing precision and coherence."
        />
        <link rel="canonical" href="/integrated-writing" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
        <header className="bg-white p-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <NavLink to="/dashboard">
              <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </NavLink>
            <NavLink
              to="/dashboard"
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Back to Home
            </NavLink>
          </div>
        </header>
        <div className=" p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {buttons.map((button, index) => (
              <button
                key={index}
                className={`${
                  scores?.hasOwnProperty(button)
                    ? "hover:bg-amber-100 bg-amber-500 "
                    : "bg-amber-400 hover:bg-amber-500"
                }bg-amber-400 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded `}
                onClick={() => handleQuestion(index)}
              >
                {scores?.hasOwnProperty(button) ? (
                  <div className="flex justify-around">
                    <div>{button}</div>
                    <div className="ml-2">Previous Score: {scores[button]}</div>
                  </div>
                ) : (
                  button
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegratedWritingQuestions;
