import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Privacy Policy - OpenStudyAI</title>
        <meta
          name="description"
          content="Read our Privacy Policy to learn about how OpenStudyAI handles your personal information."
        />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold text-teal-600">
            <Link to='/'>OpenStudyAI</Link>
          </h1>
          <Link
            to="/"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back
          </Link>
        </div>
      </header>
      <div className="w-3/4 md:w-2/4 h-full m-auto flex flex-col justify-center bg-white p-3 border rounded-lg ">
        <h1 className="text-3xl font-bold text-gray-800 mb-2">
          Privacy Policy
        </h1>
        <p className="text-gray-700 mb-4">
          At OpenStudyAI, we are committed to protecting your privacy. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you visit our website.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
          Information We Collect
        </h2>
        <p className="text-gray-700 mb-4">
          We may collect personal identification information, such as your name,
          email address, and demographic data, when you register on our site,
          subscribe to our newsletter, or fill out a form.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
          How We Use Your Information
        </h2>
        <p className="text-gray-700 mb-4">
          We use your information to improve our services, send periodic emails,
          respond to inquiries, and monitor site usage. Your information will
          not be sold or shared with third parties without your consent.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
          Security
        </h2>
        <p className="text-gray-700 mb-4">
          We implement a variety of security measures to maintain the safety of
          your personal information. However, please be aware that no security
          measures are perfect or impenetrable.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
          Contact Us
        </h2>
        <p className="text-gray-700 mb-4">
          If you have any questions about this Privacy Policy, please contact us
          at:{" "}
          <a
            href="mailto:mockxbytechnoverse@gmail.com"
            className="text-teal-600 hover:underline"
          >
            mockxbytechnoverse@gmail.com
          </a>
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
          Operational Address
        </h2>
        <p className="text-gray-700 mb-4">
          35 Survey No 82<br />
          8th Cross, Bhuvaneshwary Layout<br />
          Naganathapura, Electronics City<br />
          Bengaluru, Karnataka - 560100<br />
          India
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;