// useQuestionNavigation.js
import { useEffect } from 'react';
import { useExercise } from './ExerciseContext';

export const useQuestionNavigation = () => {
  const { currentQuestionIndex, setCurrentQuestionIndex, exerciseData } = useExercise();

  


  const handleNextQuestion = () => {

    if (currentQuestionIndex < exerciseData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }

  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSetQuestion = (questionIndex) => {
    if (questionIndex >= 0 && questionIndex < exerciseData.questions.length) {
      setCurrentQuestionIndex(questionIndex);
    }
  };

  return {
    handleNextQuestion,
    handlePreviousQuestion,
    currentQuestionIndex,
    handleSetQuestion
  };
};