import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useExercise } from './ExerciseContext';

const ConversationNumberOfQuestions = () => {
  const {questionCount, setQuestionCount} = useExercise();  // Use the context state

  

  useEffect(() => {
    const fetchQuestionCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/listening/count`);
        console.log('API Response:', response.data);  // Log the entire response
        setQuestionCount(response.data.conversations);
      } catch (error) {
        console.error('Error fetching question count:', error);
      }
    };

    fetchQuestionCount();
  }, []);

  console.log('Rendering with question count:', questionCount);  // Log the current question count

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Listening - Conversation Questions</title>
        <meta
          name="description"
          content="Practice TOEFL Listening: Conversation questions designed to enhance your comprehension of everyday dialogues. Improve your ability to grasp main ideas, details, and speaker intentions in conversational settings."
        />
        <link rel="canonical" href="/listening/conversation-questions" />
      </Helmet>

      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/listening"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Listening
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-4xl space-y-8">
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Conversation: List of Questions
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {[...Array(questionCount)].map((_, index) => (
                <Link key={index} to={`/listening/conversation/${index + 1}`}>
                  <button className="w-full py-2 px-4 bg-amber-400 text-white font-bold rounded hover:bg-amber-500 transition duration-200">
                    Question {index + 1}
                  </button>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ConversationNumberOfQuestions;