import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate,NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
// import Loading from '../LoadingPage/Loading';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isloading,setIsLoading]=useState(false)
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, password });
      if (response.data.status === 'success') {
        localStorage.setItem("OpenStudyAi-token", response.data.openaccess_token);
        setIsLoading(false);
        navigate('/dashboard');
      } else {
        setIsLoading(false);
        setError(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError('An error occurred. Please try again.');
    }
  };


  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Log in for Master TOEFL with OpenStudyAI—unleash the power of artificial intelligence for a personalized and adaptive TOEFL preparation journey. Enjoy instant feedback and tailored practice sessions to elevate your test readiness."
        />
        <meta
          property="og:title"
          content="Log in for Master TOEFL with OpenStudyAI"
        />
        <meta
          property="og:description"
          content="Log in for Master TOEFL with OpenStudyAI—unleash the power of artificial intelligence for a personalized and adaptive TOEFL preparation journey. Enjoy instant feedback and tailored practice sessions to elevate your test readiness."
        />
        <meta
          name="twitter:title"
          content="Log in for Master TOEFL with OpenStudyAI"
        />
        <meta
          name="twitter:description"
          content="Log in for Master TOEFL with OpenStudyAI—unleash the power of artificial intelligence for a personalized and adaptive TOEFL preparation journey. Enjoy instant feedback and tailored practice sessions to elevate your test readiness."
        />
        <link rel="canonical" href="/login" />
      </Helmet>
      <header className="bg-white p-4 shadow-md flex justify-center items-center">
        <NavLink to="/">
          <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
        </NavLink>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-md space-y-8">
          <div className="bg-amber-100 border-2 border-amber-300 rounded-lg p-4 mb-6">
            <h2 className="font-bold text-amber-800 mb-2">
              Important Notifications
            </h2>
            <ul className="list-disc list-inside text-sm text-amber-900">
              <li>
                OpenStudyAI's MockTOEFL™. A comprehensive test preparation
                platform.
              </li>
              <li>Practice Reading, Speaking and Writing Sections with AI!</li>
              <li>NEW: Listening Section OUT NOW! 12 Lectures and 8 Conversations are ready for practice!
                </li>
            </ul>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
              Sign In
            </h2>
            <form onSubmit={handleLogin} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 top-[20px] pr-3 flex items-center text-sm leading-5"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-400 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                  disabled={isloading}
                >
                  {isloading ? "Loading..." : "Sign In"}
                </button>
              </div>
            </form>
            {error && (
              <p className="mt-2 text-center text-sm text-red-600">{error}</p>
            )}
            <NavLink to="/forgotpassword" className='flex flex-1 justify-end mt-3 cursor-pointer text-blue-700 hover:text-blue-900'>
              forgot password
            </NavLink>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 mt-6">
            <h3 className="text-lg font-semibold mb-2 text-gray-800">
              New Users
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Create an account to schedule a test, view scores, and access the
              OpenStudyAI TestReady portal.
            </p>
            <button
              onClick={handleRegister}
              className="w-full bg-amber-400 text-white rounded-md py-2 px-4 hover:bg-amber-500 transition duration-200 text-sm font-medium"
            >
              Create an Account
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoginPage;
