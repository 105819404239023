import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import ListeningGrades from '../Profile/ListeningGrades';
import { Helmet } from 'react-helmet-async';

const ListeningAttempts = () => {
  const [selectedType, setSelectedType] = useState(null); // Step 1: Initialize state

  const handleTypeSelection = (type) => {
    setSelectedType(type); // Step 2: Update state with selected type
  };

  const renderButton = (title, description, onClick) => {
    return (
      <button onClick={onClick} className="w-full flex focus:outline-none">
        <div className="bg-amber-100 transition-colors duration-300 rounded-lg shadow-md p-6 w-full flex flex-col min-h-[170px] justify-between hover:bg-amber-200">
          <div>
            <h3 className="text-xl font-bold text-amber-800">{title}</h3>
          </div>
          <p className="text-amber-700">{description}</p>
        </div>
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex flex-col items-center justify-center px-4 py-12">
        <div className="bg-white rounded-lg shadow-md p-8 max-w-2xl w-full mb-8 min-w-[65%]">
          {selectedType ? ( // Step 3: Conditional rendering
            <div>
              <Helmet>
                <title>
                  {selectedType === 'Conversation'
                    ? 'Your progress in Listening - Conversation Questions'
                    : 'Your progress in Listening - Lecture Questions'}
                </title>
                <meta
                  name="description"
                  content={
                    selectedType === 'Conversation'
                      ? 'Monitor your progress in the TOEFL Listening section for conversation questions. Review your ability to understand everyday dialogues, including main ideas, speaker intentions, and details, and track improvements over time.'
                      : 'Track your progress in the TOEFL Listening section for lecture questions. Evaluate your understanding of academic discussions, identifying key points, arguments, and details. Stay informed of your strengths and opportunities to improve.'
                  }
                />
                <link
                  rel="canonical"
                  href={
                    selectedType === 'Conversation'
                      ? '/listening/conversation-questions'
                      : '/listening/lecture-questions'
                  }
                />
              </Helmet>

            <h1 className="pl-4 font-bold text-2xl ">
                Grades | Listening: {selectedType} 
            </h1>
            <ListeningGrades exerciseType={selectedType} />
            </div>
          ) : (
            <>
                <Helmet>
                  <title>Listing</title>
                  <meta
                    name="description"
                    content="Listening Section: Choose any question to start practicing and improve your listening skills. Engage with various audio clips and question types to sharpen your ability to understand spoken English. Focus on key areas like main ideas, details, and inferences, and track your progress to achieve greater accuracy."
                  />
                  <link rel="canonical" href="/listeningAttempts" />
                </Helmet>


              <h2 className="text-2xl font-bold text-gray-800 mb-6">
                Select the type of listening exercise:
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {renderButton(
                  "View Conversation Grades",
                  "Review your grades for listening to a conversation and answering questions",
                  () => handleTypeSelection('Conversation')
                )}
                {renderButton(
                  "View Lecture Grades",
                  "Review your grades for listening to a lecture and answering questions",
                  () => handleTypeSelection('Lecture')
                )}
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default ListeningAttempts;